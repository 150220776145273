h1,
h2,
h3,
p {
	margin: 0;
	line-height: 1.5;
}

.pageContainer {
	background: #fafafa;
	position: relative;
	display: flex;
	flex-direction: column;
	align-content: center;
	justify-content: center;
	align-items: center;
	min-height: 100%;
	text-align: center;
	color: rgb(21 25 42);
	margin: -1rem -1.5rem -50px -2rem;
	font-family: 'IBM Plex Sans', ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, sans-serif;
}

.box {
	position: relative;
	width: 800px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.circle[data-idx='0'] {
	--idx: 0
}

.circle[data-idx='1'] {
	--idx: 1
}

.circle[data-idx='2'] {
	--idx: 2
}

.circle[data-idx='3'] {
	--idx: 3
}

.circle[data-idx='4'] {
	--idx: 4
}

.circle[data-max='1'] {
	--max: 1
}

.circle[data-max='2'] {
	--max: 2
}

.circle[data-max='3'] {
	--max: 3
}

.circle[data-max='4'] {
	--max: 4
}

.circle[data-max='5'] {
	--max: 5
}

.circle {
	position: absolute;
	border: 2px solid #1919190b;
	border-radius: 50%;
	user-select: none;
	pointer-events: none;
	--idx: attr(data-idx);
	--max: attr(data-max);
}

.circle_1 {
	width: 800px;
	height: 800px;
	top: -400px;
	animation: animate 35s linear reverse infinite;
}

.circle_2 {
	width: 600px;
	height: 600px;
	top: -300px;
	animation: animate 30s linear infinite;
}

.circle_3 {
	width: 400px;
	height: 400px;
	top: -200px;
	animation: animate 25s linear reverse infinite;
}

.circle[transparent] {
	border: 2px solid transparent;
}

@keyframes animate {
	0% {
		transform: rotate(calc(var(--idx) * (360deg / var(--max)) + 0deg));
	}

	100% {
		transform: rotate(calc(var(--idx) * (360deg / var(--max)) + 360deg));
	}
}

.circle .service {
	position: absolute;
	top: -17px;
	left: 50%;
	transform: translateX(-50%) scaleY(-1) scaleX(-1);
	width: 32px;
	height: 32px;
	border-radius: 4px;
}

.circle .service img {
	width: 32px;
	height: 32px;
	object-fit: cover;
	border-radius: 4px;
}

.prContent {
	color: rgb(21 25 42);
	text-align: center;
	padding-top: 90px;
	z-index: 1;
	font-family: 'IBM Plex Sans', ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, sans-serif;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.prTitle {
	font-size: 46px;
	font-weight: 600;
	font-family: 'IBM Plex Sans Medium', ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, sans-serif;
}

.prSubTitle {
	padding-top: 46px;
	width: 570px;
	font-size: 24px;
}

.prContent .btnStart {
	margin-top: 65px;
}

.btnStart {
	text-align: center;
	border-radius: 4px;
	width: 200px;
	font-size: 16px;
	height: 50px;
	line-height: 50px;
	text-transform: uppercase;
	font-weight: bold;
	color: var(--color-text-blue);
	background: var(--color-complementary);
	box-shadow: 0px 1px 20px rgb(0 0 0 / 9%);
	user-select: none;
	transition: 0.25s;
	cursor: pointer;
}

.btnStart:hover,
.btnStart:focus {
	background: var(--color-main);
}

.prContent .btnStart:hover,
.prContent .btnStart:focus {
	box-shadow: 0 0.5em 0.5em -0.4em var(--color-main);
	background: var(--color-main);
	transform: translateY(-0.25em);
}

.prItemsTotal {
	padding-top: 52px;
}

.prItemsValue {
	line-height: 46px;
	font-size: 36px;
	font-weight: bold;
	font-family: 'IBM Plex Sans Medium', ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, sans-serif;
}

.prItemsValue[data-error='1']::after {
	content: '+';
	padding-left: 2px;
}

.prItemsTitle {
	padding-top: 12px;
	font-size: 16px;
	line-height: 21px;
}

.prTrustContent {
	position: relative;
	margin: 0 auto;
	margin-top: 52px;
	overflow: hidden;
}

.prTrustContent .prTrustContentTitle {
	width: 1440px;
	min-height: 52px;
	margin: 0 auto 48px;
	font-style: normal;
	font-weight: bold;
	font-size: 40px;
	line-height: 52px;
	text-transform: uppercase;
	font-family: 'IBM Plex Sans Medium', ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, sans-serif;
}

.contentMarquee {
	width: 99vw;
	padding: 32px 0;
	margin: 0 auto 80px;
}

.contentMarquee .contentMarqueeLeft {
	width: 100%;
	margin-bottom: 40px;
	overflow: hidden;
}

.marqueeContainer {
	overflow-x: hidden !important;
	display: flex !important;
	flex-direction: row !important;
	position: relative;
	width: 100%;
}

.marqueeContainer:hover .marquee {
	-webkit-animation-play-state: paused;
	animation-play-state: paused;
}

.overlay {
	position: absolute;
	width: 100%;
	height: 100%;
}

.overlay::before {
	left: 0;
	top: 0;
}

.overlay::after {
	right: 0;
	top: 0;
	transform: rotateZ(180deg);
}

.overlay::before,
.overlay::after {
	background: linear-gradient(to right, rgba(250, 250, 250, 1), rgba(250, 250, 250, 0));
	content: "";
	height: 100%;
	position: absolute;
	width: 200px;
	z-index: 2;
}

.marquee {
	flex: 0 0 auto;
	min-width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	animation: marquee 140s linear infinite;
}

.marqueeItem {
	display: inline-block;
	cursor: pointer;
	margin-right: 20px;
}

@keyframes marquee {
	0% {
		transform: translateX(0%);
	}

	100% {
		transform: translate(-100%);
	}
}

.bannerNew {
	position: relative;
	width: 100%;
	min-width: 1200px;
	height: 500px;
	margin-top: 100px;
	text-align: center;
	background-color: var(--color-footer);
	font-family: 'IBM Plex Sans', ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, sans-serif;
}

.bannerNew .bannerContainer {
	width: 1200px;
	margin: 0 auto;
	display: flex;
	flex-direction: row-reverse;
}

.bannerNew .banner {
	flex-grow: 0;
	width: 500px;
	text-align: left;
	padding-top: 36px;
}

.bannerNew .bannerText {
	width: 500px;
}

.bannerNew .bannerText .bannerTitle {
	padding-top: 32px;
	font-weight: 700;
	font-size: 48px;
	color: var(--color-complementary);
	font-family: 'IBM Plex Sans Medium', ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, sans-serif;
	line-height: 1.2;
}

.bannerNew .bannerText .bannerDesc {
	padding-top: 24px;
	font-size: 20px;
	color: var(--color-text-primary);
	font-family: 'IBM Plex Sans Light', ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, sans-serif;
}

.bannerNew .bannerStart {
	padding-top: 24px;
}

.bannerImg {
	position: absolute;
	top: 10%;
	left: 5%;
	width: 989px;
	height: 189px;
	transform: scaleX(-1) rotate(25deg);
	user-select: none;
	pointer-events: none;
}

.advantagesList {
	width: 1100px;
	margin: 120px auto 0;
}

.advantage {
	margin-bottom: 120px;
	display: flex;
}

.advItem {
	flex: 1;
	margin-right: 60px;
	text-align: left;
}

.advantage[data-right] .advItem {
	margin-right: 0;
}

.advItem .advTitle {
	font-weight: 600;
	font-size: 40px;
	line-height: 60px;
	margin-bottom: 16px;
	overflow: hidden;
	font-family: 'IBM Plex Sans Medium', ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, sans-serif;
}

.advItem .advDesc {
	font-size: 18px;
	color: #495665;
	margin-bottom: 24px;
	overflow: hidden;
	-o-text-overflow: ellipsis;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 3;
}

.advImg {
	width: 480px;
	height: 340px;
}

.advImg img {
	width: 340px;
	height: 340px;
	object-fit: contain;
	object-position: top;
}

.advantage[data-right] .advImg {
	margin-right: 60px;
}