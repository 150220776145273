.checkMenu {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: 200;
	background: rgba(0, 0, 0, .8);
	user-select: none;
	opacity: 0;
	pointer-events: none;
	transition: .3s opacity ease;
}

.checkMenu img.itemImg {
	height: 128px;
	width: 128px;
	object-fit: contain;
}

.checkMenu .itemName {
	user-select: text!important;
	margin-top: 0.25rem !important;
	cursor: pointer;
}

.checkMenu.flex {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: center;
}

.checkMenu.fcShow {
	opacity: 1;
	pointer-events: all;
	overflow: hidden;
}

.fcHeader {
	background-color: var(--color-background);
	padding: 20px 30px 180px 30px;
	width: 900px;
	height: 800px;
	position: relative;
	border-radius: 25px;
}

.fcHeader .fc-title {
	font-size: 22px;
	font-weight: 600;
}

.fcTitle{
	margin-bottom: 30px;
}

.fcClose {
	position: absolute;
	top: 17px;
	right: 18px;
	font-size: 22px;
	font-weight: 600;
	padding: 0px 8px 4px 8px;
	border-radius: 50%;
}

.fcContent {
	margin-top: 15px;
	flex-wrap: wrap;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	font-size: 20px;
	max-height: 400px;
	max-width: 900px;
}

.serviceLogo {
	display: inline-block;
	vertical-align: text-bottom;
	margin-right: 5px;
}

.serviceLogo img {
	width: 16px;
	height: auto;
	position: relative;
}

.fcItem strong {
	font-weight: 300;
}

.fcItem strong .count {
	color: var(--color-text-primary);
	font-size: 16px;;
}

.fcContent a {
	color: inherit;
}

.fcContent a:hover {
	text-decoration: none;
}

.fcItem strong {
	user-select: text;
}

.fcSettings {
	position: absolute;
	bottom: 20px;
	left: 0;
	width: 100%;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.fcSettingsList {
	display: inline-flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-content: center;
	margin: 10px 20px 0 20px;
	background-color: var(--color-secondary);
	border-radius: 4px;
	overflow: hidden;
	text-transform: uppercase;
}

.fcSettingsItem {
	padding: 0 10px 0 10px;
	cursor: pointer;
}

.fcSettingsItem.active {
	color: var(--color-main);
}

.fcSettingsItem:hover {
	color: var(--color-main);
	background-color: var(--color-menu-item-hover);
	box-shadow: 0px 0px 1px 0.25px #000000cc;
}

.fcItemSales
{
	display: flex;
	justify-content: space-between;
	margin-bottom: 10px
}

.fcItemSales:last-child {
	margin-bottom: 0
}

.inputStyle {
	line-height: 1.6;
	background: var(--color-secondary);
	color: var(--color-text-primary);
	border: 1px solid rgba(43, 47, 54, 0.8);
	font-weight: 600;
	width: 100px;
	border-radius: 4px;
	padding: 3px 12px 3px 12px;
	outline: none;
	height: 32px;
	margin: 0;	
}

.indentSales {
	margin: 0px 10px;
}

.fcServiceName
{
	display: inline-block;
}