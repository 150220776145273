.inputStyle {
	line-height: 1.6;
	background: var(--color-secondary);
	color: var(--color-text-primary);
	border: 1px solid rgba(43, 47, 54, 0.8);
	font-weight: 600;
	width: 100px;
	border-radius: 4px;
	padding: 3px 12px 3px 12px;
	outline: none;
	height: 32px;
	margin: 0;
}

input[type="number"]::-webkit-inner-spin-button {
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
}

form {
	display: inline-block;
}

.menuItem {
	padding-left: 30px;
	position: relative;
	font-size: 14px;
	font-weight: 400;
	letter-spacing: .7px;
	text-transform: none;
}

.menuItem i {
	position: absolute;
	top: 9px;
	left: 10px;
}

.menuItem.noAccess:hover {
	color: var(--color-text-primary) !important;
	background-color: var(--color-secondary) !important;
}

.menuItem.noAccess {
	cursor: default;
}

.noAccessPre {
	color: var(--color-main);
	font-weight: 600;
	font-size: 10px;
	float: right;
	margin-left: 3px;
}

.title {
	text-transform: none;
}

.title {
	min-width: 200px;
	text-transform: none;
}

.serviceLogo img {
	width: 14px;
	height: auto;
	position: relative;
	bottom: 1px;
}

.title .serviceLogo {
	margin-right: 4px;
}

.serviceLogo {
	display: inline-block;
	margin-left: 5px;
	margin-right: 5px;
}

.filterBtn {
	padding: 3px 12px 3px 12px;
	display: inline-flex;
	align-items: center;
	border-radius: 4px;
	border: 1px solid rgba(43, 47, 54, 0.8);
	background-color: var(--color-secondary);
	color: var(--color-text-primary);
	font-weight: 600;
	min-width: 50px;
	height: 32px;
	margin: 0px !important; 
}

.inputLiq {
	position: relative;
  	display: inline-block;
}

.inputLiq::before {
	content: attr(data-text);
	position: absolute;
	bottom: 130%;
	left: 0;
	font-size: 14px;
	font-weight: 600;
}