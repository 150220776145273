.card {
    position: relative;
    height: 190px;
    width: 190px;
    background-color: #1c222b;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 5px;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
    overflow: hidden;
}

.menuItem.currencyItem {
    padding-left: 45px;
    position: relative;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: .7px;
    text-transform: none;
}

.menuItem.currencyItem>span.currencyIcon {
    position: absolute;
    left: 12px;
}

.imageContainer {
    text-align: center;
}

.image {
    display: inline-block;
    text-align: center;
    width: 128px;
    height: 128px;
    transition: transform .4s ease;
}

.footerItem {
    padding: 0px 8px;
}

.nameContainer {
    text-wrap: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 5px;
}

.name {}

.priceContainer {
    display: flex;
    justify-content: space-between;
}

.price {}

.headerItem {
    justify-content: center;
}

.card:hover {
    color: var(--color-main) !important;
}

.card:hover .image {
    transform: scale(1.15);
}

.notMarketableCard {
    background-color: #2b1c1c !important;
}