.contextMenu {
    max-width: 250px;
    position: fixed;
    border-radius: 5px;
    padding: 5px;
    background-color: #2e2e2e;
    border: 1px solid #343434;
    z-index: 9;
    /* transition: ease-in .4s ease; */
}

.contextMenuItem {
    color: #fff;
    font-size: 14px;
    list-style-type: none;
    padding: 10px;
    cursor: pointer;
}

.contextMenuItem:hover {
    background-color: rgb(74, 74, 74);
    border-radius: 3px;
}

.line {
    margin: 4px;
}

.nameItem {
    text-wrap: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 5px;
}

ul {
    margin: 0;
    padding: 0;
}