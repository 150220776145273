.space {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: 200;
	background: rgba(0, 0, 0, .8);
	opacity: 0;
	pointer-events: none;
	transition: .3s opacity ease;
}

.space.active {
	opacity: 1;
	pointer-events: all;
}

.space .container {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.content {
	background-color: var(--color-background);
	border-radius: 25px;
	height: 600px;
	padding: 60px 30px 100px;
	position: relative;
	width: 900px;
}

.btnClose {
	border-radius: 50%;
	padding: 0 8px 4px;
	position: absolute;
	right: 18px;
	top: 17px;
	font-size: 22px;
	font-weight: 600;
}

.settings {
	position: absolute;
	bottom: 20px;
	left: 0;
	width: 100%;
	text-align: center;
	font-size: 16px;
}

.settingsList {
	display: inline-flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-content: center;
	margin: 0 20px 0 20px;
	background-color: var(--color-secondary);
	border-radius: 4px;
	overflow: hidden;
	text-transform: uppercase;
}

.settingsItem {
	padding: 0 10px 0 10px;
	cursor: pointer;
}

.settingsItem.active {
	color: var(--color-main);
}

.settingsItem:hover {
	color: var(--color-main);
	background-color: var(--color-menu-item-hover);
	box-shadow: 0px 0px 1px 0.25px #000000cc;
}

.viewContent {
	height: 100%;
}

.viewContent .loader {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.viewContent .list {
	height: 100%;
	width: 100%;
	overflow-x: hidden;
	overflow-y: scroll;
}

.list > div {
	display: flex;
	align-items: center;
	padding: 5px 20px 5px 10px;
	justify-content: space-between;
}

.list[data-list-type='0'] > div {
	height: 50px;
}

.list[data-list-type='1'] > div, .list[data-list-type='2'] > div {
	height: 68px;
}

.list > div:hover .itemName {
	color: var(--color-main);
}

.dataList {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 12px;
}

.dataList img {
	width: 64px;
	height: 64px;
	object-fit: cover;
}

.toolList {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 12px;
}

.tool {
	height: 24px;
	line-height: 24px;
}

.tool[data-tool-remove] {
	font-size: 22px;
}

.clearBtn {
	left: 0;
	bottom: 60px;
	position: absolute;
	text-align: center;
	width: 100%;
}

.currencyWarning {
	justify-content: center!important;
	color: darkred;
}