.menuItem {
	padding: 5px 30px;
}

.menuItem i {
	color: var(--color-text-primary);
}

.title {
	text-transform: capitalize;
	height: 32px;
}