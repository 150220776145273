header.appHeader {
	background-color: var(--color-header);
	width: 100%;
	color: var(--color-text-primary);
	position: sticky;
	top: 32px;
	left: 0;
	z-index: 100;
	height: 55px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: nowrap;
	font-size: 14px;
	font-family: 'IBM Plex Sans', ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, sans-serif;
	padding: 0 20px;
}

header.appHeader>* {
	height: 100%;
	display: flex;
	align-items: center;
}

header.appHeader::after {
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	border-top: 1px solid var(--color-header-border);
	width: 100%;
}

.brandLogo svg {
	height: 24px;
}

.ulLinks li>*:first-child {
	text-decoration: none;
	color: var(--color-text-primary);
	height: 100%;
	display: flex;
	align-items: center;
	padding: 0 10px;
}

.ulLinks li:not(.brandLogo):hover {
	background-color: #19191d;
}

.ulLinks li:hover>*,
.ulLinks a.active {
	color: var(--color-complementary);
}

ul.ulLinks {
	margin: 0;
	padding: 0;
	list-style: none;
	display: flex;
	align-items: center;
	height: 100%;
}

ul.ulLinks li {
	float: left;
	height: 100%;
	cursor: pointer;
}

.profile {
	display: flex;
	align-items: center;
	height: 100%;
}

.profile .profileData {
	font-size: 16px;
	margin-right: 15px;
	white-space: nowrap;
	text-align: right;
	vertical-align: middle;
	font-weight: 600;
	display: flex;
	flex-direction: column;
}

.profile .profileImage {
	cursor: pointer;
	z-index: 1;
	height: 100%;
	display: flex;
	align-items: center;
}

.profile .profileImage img {
	width: 46px;
	height: 46px;
	border-radius: 50%;
	object-fit: cover;
}

.profile .userNick {
	color: var(--color-complementary);
	font-size: 16px;
	display: block;
	font-weight: 400;
	font-family: 'IBM Plex Sans Medium', ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, sans-serif;
}

.userSubscription {
	font-size: 10px;
	color: var(--color-text-primary);
	margin-left: 3px;
	float: right;
	text-transform: uppercase;
	font-family: 'IBM Plex Sans Bold', ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, sans-serif;
}

.profile .balance {
	font-size: 14px;
	font-weight: 400;
	cursor: pointer;
}

.profile a.balance:hover {
	text-decoration: underline;
	color: var(--color-text-primary);
}

li.headerDropdown i {
	padding-left: 5px;
	vertical-align: middle;
}

/* dropdown */

.headerDropdown {
	position: relative;
	z-index: 1;
}

.headerDropdown .ddContent {
	user-select: none;
	display: none;
	position: absolute;
	top: 100%;
	left: 0;
	right: auto;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	overflow: hidden;
	background-color: var(--color-header);
	min-width: 160px;
	z-index: 3;
	border-bottom: 1px solid var(--color-header-border);
	border-left: 1px solid var(--color-header-border);
	border-right: 1px solid var(--color-header-border);
}

.headerDropdown .ddContent[dd-right] {
	left: auto;
	right: 0;
}

.headerDropdown .ddContent>* {
	color: var(--color-text-primary);
	padding: 12px 16px;
	display: block;
}

.headerDropdown:hover .ddContent {
	display: block;
}

.headerDropdown .ddContent>*:hover {
	background-color: #19191d;
}

.headerDropdown .ddContent>*:hover,
.headerDropdown .ddContent>.active {
	color: var(--color-complementary);
}

/* login button */

header.appHeader .login {
	text-align: center;
	border-radius: 4px;
	width: 100px;
	font-size: 14px;
	height: 32px;
	line-height: 32px;
	text-transform: uppercase;
	font-weight: bold;
	color: var(--color-text-blue);
	background: var(--color-complementary);
	box-shadow: 0px 1px 20px rgb(0 0 0 / 9%);
	user-select: none;
	transition: 0.25s;
	cursor: pointer;
}

header.appHeader .login>* {
	display: inline-block;
}

header.appHeader .login i {
	margin-right: 5px;
}