.productCardBox {
	position: relative;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	height: auto;
	padding: 50px;
	min-height: 800px;
	align-content: flex-start;
}

[data-hidden] {
	opacity: 0;
	pointer-events: none;
}