.mainContent {
	position: relative;
	padding-top: 30vmin;
	padding-bottom: 5vmin;
	display: flex;
	justify-content: center;
	user-select: none;
	color: var(--color-text-primary);
}

.container {
	height: 50%;
	margin-top: auto;
	margin-bottom: auto;
	text-align: center;
}

.error {
	font-size: 48px;
	font-weight: 700;
}

.mainContent a {
	display: inline-block;
	text-decoration: none;
	font-size: 32px;
	font-weight: 300;
	font-style: italic;
	color: var(--color-main);
}

.mainContent .icon {
	display: flex;
	justify-content: center;
	align-items: center;
}

.mainContent a:hover ::before {
	transform: translateX(-20px);
}

.icon ::before {
	font-size: 32px;
	display: inline-block;
	margin-right: 15px;
	vertical-align: middle;
	transition: 250ms transform ease;
}