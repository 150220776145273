footer {
	position: absolute;
	bottom: 0;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	background-color: var(--color-footer);
	height: 100px;
	width: 100%;
	white-space: nowrap;
	color: var(--color-text-primary);
	font-size: 14px;
	font-family: 'IBM Plex Sans', ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, sans-serif;
}

footer::before {
	content: "";
	position: absolute;
	top: 0;
	border-top: 1px solid var(--color-footer-border);
	width: 60%;
}

.footerList {
	width: 60%;
	display: grid;
	grid-template-columns: 1fr 1fr 100fr 1fr;
	align-items: center;
}

.itemsList:not(:last-child) {
	margin-right: 40px;
}

.footerList .itemsList>* {
	display: inline-block;
}

.footerList .itemsList a:hover {
	color: var(--color-complementary);
}

.footerList .itemsList>*:not(:last-child) {
	margin-right: 23px;
}

.footerList .footerLinks>*:not(:last-child) {
	margin-right: 7px;
}

.footerLinks img {
	width: 24px;
	height: 24px;
	object-fit: cover;
}

.brandLogo svg {
	height: 36px;
}