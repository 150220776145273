.avatar img {
	height: 184px;
	width: 184px;
	border-radius: 4px;
}

.userData {
	display: inline-block;
	vertical-align: top;
	margin-left: 34px;
	height: 200px;
}

.userNickname {
	color: var(--color-main);
	font-size: 20px;
	cursor: pointer;
	user-select: none;
	font-weight: 600;
}

.userNickname:hover {
	text-decoration: underline;
}

.simpleData {
	font-size: 18px;
	font-weight: 400;
	margin: 7px 0;
	width: max-content;
}

.simpleData span {
	font-weight: 600;
}

.simpleData img {
	height: 32px;
}

.btnPay {
	background: var(--color-secondary);
	border-radius: 4px;
	padding: 3px 12px 3px 12px;
	text-transform: capitalize;
	cursor: pointer;
	font-size: 14px;
	user-select: none;
	font-weight: 600;
}

.btnPause {
	background: var(--color-secondary);
	border-radius: 4px;
	padding: 3px 12px 3px 12px;
	text-transform: capitalize;
	cursor: pointer;
	font-size: 14px;
	user-select: none;
	font-weight: 600;
	margin-top: 0.3rem;
}

.btnPauseDisabled {
	cursor: default;
}

.subTimeRemain {
	color: var(--color-main);
	font-size: 13px;
	margin-left: 7px;
	float: right;
}

/* promo */

.promo {
	display: inline-block;
	vertical-align: top;
	margin-left: 150px;
	text-align: center;
	font-size: 18px;
}

.promo .promoTitle {
	text-align: center;
	font-weight: 600;
	margin-bottom: 10px;
	color: var(--color-main);
}

.btnPromo {
	background: var(--color-secondary);
	border-radius: 4px;
	padding: 3px 12px 3px 12px;
	text-transform: capitalize;
	cursor: pointer;
	user-select: none;
	font-weight: 600;
	width: 160px;
	display: inline-block;
	font-size: 14px;
	margin-top: 15px;
	text-align: center;
}

.promo .inputStyle {
	background: var(--color-secondary);
	color: var(--color-text-primary);
	border: 1px solid rgba(43, 47, 54, 0.8);
	font-weight: 600;
	width: 160px;
	text-align: center;
	border-radius: 4px;
	padding: 3px 12px 3px 12px;
	outline: none;
	font-size: 14px;
}

.referralsInfo {
	font-size: 12px;
	text-align: left;
	margin-top: 15px;
	font-weight: 600;
}

.promoLoading img {
	width: 64px;
	height: 64px;
}

/* transactions */

.transactions {
	margin-top: 50px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.transactions .transactionTitle {
	color: var(--color-main);
	font-size: 22px;
}

.transContent {
	margin-top: 20px;
	width: 100%;
}

.transItem {
	display: grid;
	grid-template-columns: 0fr 1fr;
	padding: 5px 5px;
	align-items: stretch;
	font-size: 14px;
}

.transItem>div {
	padding: 5px 15px;
}

.transactionId {
	width: 200px;
	text-align: center;
	background: var(--color-secondary);
	border-bottom-left-radius: 8px;
	border-top-left-radius: 8px;
	position: relative;
	height: 52px;
}

.transactionId::after {
	content: '';
	width: 2px;
	height: 100%;
	position: absolute;
	right: 0;
	top: 0;
}

.transactionId[data-status='positive']::after {
	background-color: var(--success);
}

.transactionId[data-status='negative']::after {
	background-color: var(--danger);
}

.transactionDesc {
	position: relative;
	background: var(--color-secondary);
	display: flex;
	align-items: center;
	justify-content: flex-start;
	border-bottom-right-radius: 8px;
	border-top-right-radius: 8px;
	height: 52px;
}

.transactionDesc img {
	position: absolute;
	top: 22%;
	margin-left: 0.25rem;
}

.transLoading {
	margin-top: 15px;
	height: 27px;
}