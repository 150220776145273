:root {
	--toast-width: 270px;
	--toast-border-radius: 0.25rem;
	--toast-theme-default: #fff;
	--toast-theme-primary: #0d6efd;
	--toast-theme-secondary: #6c757d;
	--toast-theme-success: #198754;
	--toast-theme-danger: #dc3545;
	--toast-theme-warning: #ffc107;
	--toast-theme-info: #0dcaf0;
	--toast-theme-light: #f8f9fa;
	--toast-theme-dark: #212529;
}

.toast-container {
	position: fixed;
	bottom: 15px;
	right: 15px;
	width: var(--toast-width);
}

.toast {
	font-size: 0.875rem;
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, 0.05);
	border-radius: var(--toast-border-radius);
	box-shadow: 0 .125rem .25rem rgba(0, 0, 0, 0.075);
	position: relative;
	overflow: hidden;
	opacity: 1;
	transition: 0.3s opacity;
}

.toast_default {
	color: #212529;
	background-color: var(--toast-theme-default);
}

.toast_primary {
	color: #fff;
	background-color: var(--toast-theme-primary);
}

.toast_secondary {
	color: #fff;
	background-color: var(--toast-theme-secondary);
}

.toast_success {
	color: #fff;
	background-color: var(--toast-theme-success);
}

.toast_danger {
	color: #fff;
	background-color: var(--toast-theme-danger);
}

.toast_warning {
	color: #212529;
	background-color: var(--toast-theme-warning);
}

.toast_info {
	color: #212529;
	background-color: var(--toast-theme-info);
}

.toast_light {
	color: #212529;
	background-color: var(--toast-theme-light);
}

.toast_dark {
	color: #fff;
	background-color: var(--toast-theme-dark);
}

.toast_custom {
	color: var(--color-header);
	background: linear-gradient(var(--color-main) 0%, var(--color-main) 100%);
}

.toast_custom ::selection {
	color: var(--color-main) !important;
	background-color: var(--color-header) !important;
}

.toast:not(:last-child) {
	margin-bottom: 0.75rem;
}

.toast:not(.toast_show) {
	display: none;
}

.toast_showing {
	opacity: 0;
}

.toast__header {
	position: relative;
	padding: 0.5rem 2.25rem 0.5rem 1rem;
	background-color: rgba(0, 0, 0, 0.03);
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.toast__close {
	content: "";
	position: absolute;
	top: 0.75rem;
	right: 0.75rem;
	width: 0.875em;
	height: 0.875em;
	background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/0.875em auto no-repeat;
	border: 0;
	opacity: 0.5;
	cursor: pointer;
	transition: opacity 0.1s ease-in-out;
	outline: none!important;
}

.toast__close:hover {
	opacity: 1;
}

.toast__body {
	padding: 1rem;
}

.toast_message .toast__body {
	padding-right: 2.25rem;
}