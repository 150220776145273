.subSocial {
	text-align: center;
	font-size: 18px;
	margin-bottom: 2rem;
}

.subWarningTitle {
	color: darkred;
}

.subSuccessTitle {
	color: green;
}

.subContainer {
	margin-bottom: 2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	align-content: center;
	flex-direction: row;
	flex-wrap: wrap;
}

.subItem {
	width: 300px;
	height: 300px;
	border-radius: 25px;
	background: var(--color-secondary);
	display: inline-flex;
	flex-wrap: nowrap;
	flex-direction: column;
	align-content: center;
	justify-content: space-between;
	align-items: center;
	vertical-align: top;
	position: relative;
	user-select: none;
	margin: 25px;
	transition: .3s all ease;
}

.subItem {
	--color-sub: var(--color-main);
}

.subItem[data-sub='base'] {
	--color-sub: var(--color-sub-base);
}

.subItem[data-sub='pro'] {
	--color-sub: var(--color-sub-pro);
}

.subItem:hover {
	background-color: var(--color-sub-item-hover);
}

.subItem[data-sub='base']:hover {
	box-shadow: 0 0 2px var(--color-sub-base), 0 0 6px var(--color-sub-base), 0 0 10px var(--color-sub-base);
}

.subItem[data-sub='pro']:hover {
	box-shadow: 0 0 2px var(--color-sub-pro), 0 0 6px var(--color-sub-pro), 0 0 10px var(--color-sub-pro);
}

.subItem svg {
	margin-top: 50px;
	height: 150px;
	pointer-events: none;
	fill: var(--color-sub);
}

.subItem .subText {
	margin: 0;
	margin-bottom: 10px;
	height: 20px;
}

.subItem span.subTitle {
	color: var(--color-sub);
	font-weight: 600;
	letter-spacing: 3px;
	text-transform: uppercase;
}

.subItem .buyBtn:hover {
	background-color: var(--color-sub) !important;
}

.subItem span {
	font-size: 20px;
	font-weight: 600;
}

.subItem span.buyBtn {
	padding: 5px 16px;
	border-radius: 8px;
	background-color: rgb(63, 69, 77);
	margin-bottom: 20px;
	user-select: none;
	cursor: pointer;
	font-size: 16px;
	min-width: 125px;
	text-align: center;
}

.subItem .infoBtn {
	position: absolute;
	top: 7px;
	left: 5px;
	width: 50px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	font-size: 18px;
}

.subItem .infoBtn:hover {
	color: var(--color-sub) !important;
}

/* text */

.subTitle {
	font-weight: 600;
	font-size: 22px;
	line-height: 200%;
}

.subTitle strong {
	color: var(--color-main);
	font-weight: 600;
}

span.subPrice {
	min-height: 48px;
	display: flex;
	align-content: center;
	justify-content: center;
	align-items: center;
	flex-wrap: nowrap;
}

.subDiscount {
	position: absolute;
	font-size: 18px;
	font-weight: 600;
	top: -3%;
	right: -5%;
	color: white;
	padding: 5px 12px 5px 20px;
	background: darkred;
	clip-path: polygon(0 50%, 20% 0, 100% 0, 100% 100%, 20% 100%);
}

/* Balance */

.balanceTitle {
	color: var(--color-main);
	font-size: 22px;
	text-align: center;
}

.balanceMessage {
	font-weight: 600;
	font-size: 18px;
}

.balanceMessage strong {
	color: var(--color-complementary);
	font-weight: 600;
}

.balanceDeposit {
	margin-top: 125px;
	text-align: center;
	display: flex;
	justify-content: center;
	gap: 50px;
}

.itemDeposit {
	width: 300px;
	height: 300px;
	border-radius: 25px;
	background: var(--color-secondary);
	display: inline-flex;
	flex-wrap: nowrap;
	flex-direction: column;
	align-content: center;
	justify-content: space-between;
	align-items: center;
	transition: .3s all ease;
	position: relative;
}

.itemDeposit.inner {
	border: 2px solid #000;
  position: absolute;
  top: 50px;
  left: 50px;
  width: 100px;
  height: 100px;
}

.itemDeposit:hover {
	background-color: var(--color-sub-item-hover);
}

.itemDeposit .depositHeader {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.itemDeposit .depositFooter {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.itemDeposit .depositBtn {
	font-weight: 600;
	padding: 5px 16px;
	border-radius: 8px;
	background-color: rgb(63, 69, 77);
	margin-bottom: 20px;
	min-width: 125px;
	user-select: none;
}

.itemDeposit span.itemName {
	font-size: 18px;
	margin-bottom: 12px;
}

.itemDeposit img {
	max-width: 270px;
	width: auto;
	height: 200px;
}

.itemDeposit img.cryptoImg {
	height: 148px;
}

.itemDeposit img.animImg {
	transition: .3s all ease;
}

.itemDeposit:hover img.animImg {
	transform: scale(1.1) rotate(5deg);
}

.itemDeposit:hover img.animImg.cryptoImg {
	transform: scale(1.1);
}

.depositLoading img {
	width: 42px;
	height: 42px;
	margin-bottom: 12px;
}

.messageForUser {
	margin-bottom: 20px;
}

.anotherPay {
	margin-bottom: 20px;
}

.balanceWarning {
	color: var(--color-complementary);
	font-size: 14px;
	font-weight: 600;
	cursor: default;
	line-height: 29px;
}

.balanceWarning>span {
	background-color: var(--color-secondary);
	padding: 5px 10px;
	border-radius: 5px;
	height: 29px;
}

.itemDeposit img.vvKeysLogo {
	transition: .3s all ease;
	clip-path: circle();
}

.itemDeposit:hover img.vvKeysLogo {
	transform: scale(1.1) rotate(5deg);
}

.itemDeposit:hover img.vvKeysLogo.cryptoImg {
	transform: scale(1.1);
}

img.tgLogo {
	width: 24px;
    height: 24px;
    object-fit: cover;
	display: block;
}

.imageBackground,
.imageOverlay {
  width: 100%;
  height: auto;
  display: block;
  transition: transform 0.3s ease; /* плавное увеличение */
}

.imageBackground {
	width: 100%;
	height: auto;
	display: block;
  }
  
.imageOverlay {
	position: absolute;
	bottom: 43%;
	right: 29%;
	transform: translate(0, 0); /* начальное положение */
  	transition: transform 0.3s ease;
  }

  .itemDeposit:hover .imageBackground,
.itemDeposit:hover .imageOverlay {
  	transform: scale(1.1) translate(5px, 5px); 
}