.menuItem {
    padding: 5px 30px;
    width: 180px;
}

.menuItemPro {
    padding-left: 30px;
    position: relative;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: .7px;
    text-transform: none;
}   

.menuItem.noAccess:hover {
	color: var(--color-text-primary) !important;
	background-color: var(--color-secondary) !important;
}

.menuItem i {
	color: var(--color-text-primary);
}

.title {
	text-transform: capitalize;
    height: 32px;
}

.noAccessPre {
	color: var(--color-main);
	font-weight: 600;
	font-size: 10px;
	float: right;
	margin-left: 3px;
}

.menuItem.noAccess {
	cursor: default;
}
