h1.pageTitle {
	text-align: center;
	font-size: 2.5rem;
	color: var(--color-main)
}

.supportMessenger {
	text-align: center;
	font-weight: 100;
	font-size: 26px;
	line-height: 42px;
}

.supportMessenger img {
	height: 32px;
	width: 32px;
	object-fit: cover;
	margin-right: 10px;
	vertical-align: text-bottom;
}

.supportMessenger a {
	display: inline-block;
}
