.tosTitle {
	text-align: center;
	color: var(--color-main);
}

.tosContent {
	font-weight: 400;
	font-size: 16px;
	margin-top: 10px;
}

.tosTextContent {
	text-align: justify;
	text-indent: 30px;
	padding: 5px 0;
	padding: 5px 20px;
}

.tosParagraph {
	font-weight: 600;
	font-size: 20px;
	padding: 10px 0 10px 30px;
}