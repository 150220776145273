.freeSpace {
	min-width: 775px;
	height: 0;
}

.page {
	position: relative;
	display: flex;
	flex-direction: column;
	min-height: 100%;
}

.page.full-size .main {
	padding-left: 192px;
}

.main {
	flex: 1;
	position: relative;
	padding-bottom: 150px;
	font-family: Gotham SSm A, Gotham SSm B, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
	transition-duration: 250ms;
}

.sidebar {
	background-image: linear-gradient(180deg, rgb(5, 39, 103) 0%, #3a0647 70%);
}

@media (min-width: 0px) {
	.page {
		flex-direction: row;
	}

	.sidebar {
		width: 250px;
		height: 100vh;
		position: sticky;
		top: 0;
	}

	.main>div {
		padding-left: 2rem !important;
		padding-right: 1.5rem !important;
	}
}

.promo-bar {
	background-color: var(--color-background);
	color: var(--color-main);
	cursor: default;
	position: absolute;
	width: 100%;
	top: 100%;
	left: 0;
	padding: 2px;
}

.promo-bar>div {
	animation: promo-move 30s infinite;
	display: inline-block;
	animation-timing-function: linear;
}

@keyframes promo-move {
	0% {
		transform: translateX(101vw)
	}

	100% {
		transform: translateX(-100%)
	}
}

@-moz-keyframes promo-move {
	0% {
		transform: translateX(101vw)
	}

	100% {
		transform: translateX(-100%)
	}
}

.page.full-size #menu {
	width: 192px;
}

#menu {
	position: fixed;
	min-height: 100%;
	width: 64px;
	background-color: var(--color-header);
	border-right: 1px solid var(--color-header-border);
	z-index: 200;
	display: grid;
	grid-template-rows: 0fr 1fr 0fr 0fr 0fr;
	justify-content: start;
	text-align: center;
	padding: 0;
	margin: 0;
	transition-duration: 250ms;
}

.page.full #menu-logo .logo-svg {
	text-align: left;
	padding-left: 15px !important;
}

#menu-logo .logo-svg {
	padding: 20px 0;
}

.logo-svg {
	cursor: pointer;
}

.page.full #menu .logo-svg svg {
	width: auto;
	height: 32px;
}

.logo-svg svg {
	width: 32px;
	height: 32px;
}

.page.full-size #menu #menu-content {
	width: 191px;
}

#menu #menu-content {
	width: 63px;
	font-size: 20px;
	display: flex;
	flex-direction: column;
}

.menu-elem {
	cursor: pointer;
	width: 100%;
}

.menu-elem i {
	text-align: center;
	width: 20px;
}

.page.full #menu #menu-content .menu-elem span.menu-title {
	display: inline;
	vertical-align: middle;
}

.menu-elem span.menu-title {
	font-size: 14px;
	margin-left: 5px;
	display: none;
}

.page.full ::deep .menu-elem a {
	text-align: left;
	padding-left: 21px;
}

.menu-elem a {
	padding: 7px 5px;
	display: block;
}

.menu-elem a.active {
	position: relative;
	color: var(--color-main);
}

.menu-elem:hover a {
	background-color: var(--color-menu-elem-hover);
	color: var(--color-main);
}

.menu-elem a.active>div[slash] {
	background-color: var(--color-main);
	width: 3px;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
}

div#menu-footer {
	font-size: 14px;
	padding: 5px 0;
}

.full div#menu-lang {
	padding: 10px 0;
}

#menu-lang .lang-list {
	display: none;
}

.full #menu-lang .lang-list {
	display: flex;
	justify-content: center;
	align-items: center;
}

.full #menu-lang .lang-list div {
	width: 24px;
	cursor: pointer;
	margin: 0px 5px;
}