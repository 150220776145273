.space {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: 250;
	background: rgba(0, 0, 0, .9);
	opacity: 0;
	pointer-events: none;
	transition: .25s opacity ease;
	font-size: 25px;
}

.space.active {
	opacity: 1;
	pointer-events: all;
}

.space .container {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.space .container input {
	background: none;
	border-bottom: 1px solid #ffffff55;
	border-radius: 0;
	width: 250px;
	font-weight: 400;
	font-size: 22px;
}

.space .container .btn {
	color: var(--color-main);
}