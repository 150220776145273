.checkMenu {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: 200;
	background: rgba(0, 0, 0, .8);
	user-select: none;
	opacity: 0;
	pointer-events: none;
	transition: .3s opacity ease;
}

.checkMenu img.itemImg {
	height: 128px;
	width: 128px;
	object-fit: contain;
}

.checkMenu .itemName {
	user-select: text!important;
	margin-top: 0.25rem !important;
	cursor: pointer;
}

.checkMenu.flex {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: center;
}

.checkMenu.cmShow {
	opacity: 1;
	pointer-events: all;
	overflow: hidden;
}

.cmHeader {
	background-color: var(--color-background);
	padding: 20px 30px 180px 30px;
	width: 900px;
	height: 800px;
	position: relative;
	border-radius: 25px;
}

.cmHeader .cm-title {
	font-size: 22px;
	font-weight: 600;
}

.cmClose {
	position: absolute;
	top: 17px;
	right: 18px;
	font-size: 22px;
	font-weight: 600;
	padding: 0px 8px 4px 8px;
	border-radius: 50%;
}

.cmContent {
	margin-top: 15px;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	align-content: space-around;
	align-items: flex-start;
	justify-content: center;
	font-size: 20px;
	max-height: 400px;
	max-width: 900px;
}

.cmItem .serviceLogo {
	display: inline-block;
	vertical-align: text-bottom;
	margin-right: 5px;
}

.cmItem .serviceLogo img {
	width: 16px;
	height: auto;
	position: relative;
}

.cmItem strong {
	font-weight: 300;
}

.cmItem strong .count {
	color: var(--color-text-primary);
	font-size: 16px;;
}

.cmContent a {
	color: inherit;
}

.cmContent a:hover {
	text-decoration: none;
}

.cmItem strong {
	user-select: text;
}

.cmSettings {
	position: absolute;
	bottom: 20px;
	left: 0;
	width: 100%;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.cmSettingsList {
	display: inline-flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-content: center;
	margin: 10px 20px 0 20px;
	background-color: var(--color-secondary);
	border-radius: 4px;
	overflow: hidden;
	text-transform: uppercase;
}

.cmSettingsItem {
	padding: 0 10px 0 10px;
	cursor: pointer;
}

.cmSettingsItem.active {
	color: var(--color-main);
}

.cmSettingsItem:hover {
	color: var(--color-main);
	background-color: var(--color-menu-item-hover);
	box-shadow: 0px 0px 1px 0.25px #000000cc;
}
