.faqContent {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: flex-start;
	height: 100%;
}

.faqQuestions {
	width: 350px;
	height: 100%;
	padding: 0 10px;
	position: fixed;
}

.faContent ul, .faContent ol {
	margin-left: 20px;
	padding-top: 10px;
	margin-bottom: 0;
}

.faqContent li {
	text-indent: 0;
}

.faqQuestions .fqTitle {
	font-weight: 600;
	font-size: 24px;
	color: var(--color-main);
	text-align: center;
}

.fqContent {
	margin-top: 15px;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	align-items: stretch;
	white-space: nowrap;
	user-select: none;
	background-color: var(--color-secondary);
	border-radius: 2px;
	font-weight: 600;
	padding-bottom: 50px;
	overflow: hidden;
}

.fqContent>.fqNavItem {
	display: block;
	transition: .1s all ease;
	padding: 8px 40px 8px 20px;
}

.fqContent>.fqNavItem.active {
	color: var(--color-main);
	cursor: default;
}

.fqContent>.fqNavItem:not(.active):hover {
	background-color: var(--color-menu-item-hover);
}

/* answers */

.faqAnswerContent {
	margin-left: 350px;
	width: 100%;
	padding: 0 20px;
	font-weight: 600;
	font-size: 24px;
}

.facMainTitle {
	padding: 15px 0;
	font-size: 20px;
	font-weight: 600;
	cursor: default;
}

.facMainTitle>* {
	background-color: var(--color-secondary);
	padding: 5px 15px 8px 15px;
	border-radius: 5px;
	font-size: 20px;
	display: inline-block;
}

.faTitle {
	font-size: 24px;
	text-align: center;
	color: var(--color-main);
}

.faContent {
	font-weight: 400;
	font-size: 16px;
	margin-top: 10px;
}

.animatedElement {
	animation-name: show-anim;
	animation-duration: .5s;
}

.facTitle {
	font-weight: 600;
	font-size: 20px;
	padding: 10px 0 10px 30px;
}

.facTextContent {
	text-align: justify;
	text-indent: 30px;
	padding: 5px 0;
}

.facImg {
	text-align: center;
	padding: 20px 0;
}

.facImg img {
	border: var(--color-menu-item-hover) solid 1px;
	box-shadow: rgb(53 59 67) 0px 0px 5px;
	max-width: 720px;
	max-height: 720px;
}

.faContent strong {
	font-weight: 400;
	color: var(--color-main);
	cursor: pointer;
	background-color: var(--color-secondary);
	padding: 0px 7px 2px 7px;
	border-radius: 5px;
}

@keyframes show-anim {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}