.container {
	position: fixed;
	cursor: pointer;
	width: 100%;
	height: 32px;
	z-index: 100;
	padding: 0 !important;
	left: 0;
	top: 0;
}

.container img {
	height: 32px;
	width: 100%;
	object-fit: cover;
	object-position: left;
	vertical-align: baseline;
}

.container a {
	display: block;
	height: 100%;
}