.listItem {
	height: 68px;
}

.listItem>* {
	display: inline-block;
	vertical-align: middle;
	text-align: center;
	padding: 10px 20px 10px 20px;
	user-select: none;
}

.mainTable .head {
	height: 75px;
}

.listItem:hover {
	background-color: rgb(21 27 35) !important;
}

.listItem[data-selected] {
	background-color: rgb(21 27 35) !important;
}

.listItem[data-selected]::before,
.listItem[data-selected]::after {
	content: "";
	height: 100%;
	position: absolute;
	top: 0;
	width: 5px;
	background-color: var(--color-complementary);
}

.listItem[data-selected]::before {
	left: 0;
}

.listItem[data-selected]::after {
	right: 0;
}

.listItem:hover .tdTools>div {
	visibility: visible
}

.tableName {
	width: 35%;
}

.tableTools {
	width: 13%;
}

.tableSales {
	width: 6%;
}

.tablePrice {
	width: 12%;
}

.tableProfit {
	width: 10%;
}

.tableCount {
	width: 6%;
}

.tableLiquidity {
	width: 7%;
}

.tableNameWithL {
	width: 35%;
}

.tableToolsWithL {
	width: 10%;
}

.tableSalesWithL {
	width: 6%;
}

.tablePriceWithL {
	width: 10%;
}

.tableProfitWithL {
	width: 9%;
}

.tableCountWithL {
	width: 6%;
}

.flex {
	display: flex;
	justify-content: center;
	align-items: center;
}

.linkGray a {
	color: var(--color-text-primary);
}

.filterContent {
	user-select: none;
	min-width: 1200px;
	margin-top: 30px;
}

.tableContent {
	margin-top: 30px;
}

.tableLoading {
	margin-top: 50px;
}

.tableLoading img {
	width: 92px;
	height: auto;
}

.mainTable .head>* {
	text-align: center;
	display: inline-block;
	vertical-align: middle;
}

.mainTable .head>* {
	padding: 5px 20px 5px 20px;
	user-select: none;
}

.mainTable {
	width: 100%;
	font-size: 16px;
	border-radius: 4px;
}

.mainTable .head span {
	display: inline-block;
	cursor: pointer;
}

.listItem>* span {
	display: inline-block;
	cursor: pointer;
}

.mainTable .head {
	background-color: var(--color-secondary);
}

.listItem:nth-child(2n+1) {
	background-color: rgb(28, 34, 43);
}

.listItem:nth-child(2n) {
	background-color: rgb(33, 39, 47);
}

.count {
	font-size: 16px;
	font-weight: 600;
}

.count.warning {
	color: rgb(150 50 50);
}

div.count {
	position: absolute;
	left: auto;
	right: auto;
	bottom: 0;
	color: var(--color-text-primary);
}

.head div.headService {
	font-size: 12px;
	color: var(--color-main);
	font-weight: 700;
}

.listItem .tdImg {
	padding: 0 5px 0 35px !important;
	width: 72px;
}

.listItem .tdImg img {
	height: 62px;
	width: 62px;
}

.listItem .tdTools>div {
	visibility: hidden;
}

.listItem .tdTools .toolItem {
	display: inline-block;
	padding: 0 4px;
	cursor: pointer;
}

.listItem .tdTools .toolItem:hover {
	color: var(--color-main);
}

.listItem .tdName {
	cursor: pointer;
}

.listItem .tdName .nameContent,
.listItem .tdPrice {
	user-select: text !important;
}

.listItem[data-favorite='1'] .tdName .nameContent {
	color: var(--color-main);
}

.listItem[data-favorite='0'] .favoriteIcon::before {
	font-weight: 400;
}

.tdName {
	position: relative;
}

.listItem .tdName .wtime {
	font-size: 13px;
	font-weight: 600;
	color: rgb(150, 50, 50);
	position: absolute;
	bottom: 5px;
	left: 0;
	right: 0;
}

.listItem .tdSales div.flex {
	cursor: pointer;
}

.listItem .tdSales,
.listItem .tdCount {
	position: relative;
}

.listItem .tdPrice,
.listItem .tdProfit .tdLiquidity {
	font-weight: 600;
}

.listItem .tdPrice .sourcePrice {
	font-weight: 400;
	font-size: 12px;
}

.listItem .tdTime {
	width: 150px;
}

.listItem .tdCount img,
.listItem .tdSales img {
	width: 24px;
	height: auto;
	margin-bottom: 13px;
}

.profitPositive {
	color: rgb(50 150 50);
}

.profitNegative {
	color: rgb(150 50 50);
}

.tableMessage {
	font-weight: 600;
	font-size: 18px;
	text-align: center;
	margin-top: 30px;
}

/* sales-info */

.salesInfo {
	position: absolute;
	background: var(--color-header);
	padding: 5px 15px 8px 15px;
	z-index: 3;
	bottom: 10px;
	left: auto;
	right: 70%;
	min-width: 190px;
	height: max-content;
	font-size: 12px;
	font-weight: 600;
	text-align: left;
	border-radius: 4px;
	transition: 200ms opacity ease;
	opacity: 0;
	pointer-events: none;
	cursor: default;
	box-shadow: rgb(48 52 58) 0px 0px 5px inset;
	min-height: 210px;
}

.salesInfo.down {
	top: 10px;
	bottom: 0;
}

.salesInfo.saleShow {
	opacity: 1;
	pointer-events: all;
}

.salesInfo .header {
	text-align: center;
	padding: 3px 0;
	color: var(--color-main);
	letter-spacing: .3px;
}

.salesDesc {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	text-align: center;
	margin-top: 5px;
}

.singleSaleInfo {
	display: inline-block;
	font-size: 10px;
	font-weight: 700;
	color: var(--color-main);
	letter-spacing: .5px;
	padding: 0px 8px;
	flex: 40%;
}

.singleSaleInfo>div {
	color: var(--color-text-primary);
	font-size: 12px;
	font-weight: 600;
	letter-spacing: 0px;
	white-space: nowrap;
}

.salesLast {
	display: grid;
}

.salesLastItem {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 2px 0px;
	white-space: nowrap;
}

.salesLastItem div {
	display: inline-block;
}

.salesLastItem .salesLastSource {
	font-size: 10px;
}

.salesLastItem .salesLastSource::before {
	content: '('
}

.salesLastItem .salesLastSource::after {
	content: ')'
}

.salesInfo .saleLoader {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
}

/* filters */

div.topFilters {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

div.filtersFooter {
	margin-top: 40px;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	font-size: 14px;
	font-weight: 600;
	padding: 0px 10px;
}

div.tableBtn {
	padding: 3px 12px 3px 12px;
	display: inline-flex;
	align-items: center;
	border-radius: 4px;
	border: 1px solid rgba(43, 47, 54, 0.8);
	background-color: var(--color-secondary);
	color: var(--color-text-primary);
	font-weight: 600;
	min-width: 50px;
	height: 32px;
}

.servicesFilters {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
}

.servicesFilters .title {
	min-width: 200px;
	text-transform: none;
}

.titleReset {
	height: 32px
}

.serviceTitle {
	font-weight: 600;
	font-size: 14px;
	margin: 0 0 10px 30px;
	display: flex;
}

.serviceTitle div {
	display: inline-block;
}

input.searchName {
	background: #21272f;
	display: inline-block;
	margin: 5px 0;
	color: var(--color-text-primary);
	border: 1px solid rgba(43, 47, 54, 0.8);
	font-size: 14px;
	width: 300px;
	border-radius: 4px;
	padding: 3px 12px 3px 12px;
	outline: none;
	font-weight: 400;
}

.inputStyle {
	margin-left: 10px;
	background: var(--color-secondary);
	color: var(--color-text-primary);
	border: 1px solid rgba(43, 47, 54, 0.8);
	font-weight: 600;
	width: 100px;
	border-radius: 4px;
	padding: 3px 12px 3px 12px;
	outline: none;
}

input.inputStyle.profit, input.inputStyle.liquidity {
	font-size: 14px;
	margin: 8px 0 0 0;
	height: 32px;
}

.sortHeader {
	position: relative;
	display: inline-block;
	cursor: pointer;
}

.sortHeader::after {
	font-family: "Font Awesome 5 Free";
	content: "\f0dc";
	font-weight: 900;
	margin-left: 5px;
	display: inline-block;
	position: absolute;
}

.sortHeader.asc::after,
.sortHeader.desc::after {
	content: "\f0d7";
}

.sortHeader.asc::after {
	top: 2px;
	transform: rotate(180deg);
}

/* subscription alert */

.subscriptionAlert {
	color: var(--color-text-primary);
	font-weight: 600;
	font-size: 18px;
	text-align: center;
	margin: 10px 0 40px 0;
}

.subscriptionAlert a {
	color: var(--color-main);
	display: inline-block;
	cursor: pointer;
}

.subscriptionAlert a:hover {
	text-decoration: underline;
}

/* filters */

.servicesSwapBtn {
	background: var(--color-secondary);
	padding: 5px 15px;
	border-radius: 8px;
}

.profileTitle {
	width: 200px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.menuItem.profileItem {
	font-size: 14px;
	font-weight: 400;
	position: relative;
	text-transform: none;
}

.menuItem.profileItem.addNew {
	text-align: center;
}

.menuItem.currencyItem {
	padding-left: 45px;
	position: relative;
	font-size: 14px;
	font-weight: 400;
	letter-spacing: .7px;
	text-transform: none;
}

.menuItem.currencyItem>span.currencyIcon {
	position: absolute;
	left: 12px;
}

.btnMoreData {
	background: var(--color-secondary);
	font-size: 14px;
	font-weight: 600;
	padding: 3px 12px;
	text-align: center;
	width: 160px;
	border-radius: 4px;
	cursor: pointer;
	display: inline-block;
	margin-top: 15px;
	text-transform: capitalize;
	user-select: none;
}