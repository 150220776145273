.confirmSpace {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: 250;
	background: rgba(0, 0, 0, .8);
	opacity: 0;
	pointer-events: none;
	transition: .25s opacity ease;
	font-size: 25px;
}

.confirmSpace.confShow {
	opacity: 1;
	pointer-events: all;
}

.confirmForm {
	background-color: var(--color-background);
	padding: 20px 30px;
	width: 300px;
	min-height: 150px;
	position: relative;
	border-radius: 4px;
	padding-bottom: 85px;
	text-align: center;
}

.cfButtons {
	position: absolute;
	width: 100%;
	bottom: 25px;
	left: 0;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-content: center;
	justify-content: space-evenly;
	align-items: center;
	padding: 0 20px;
}

.cfBtn {
	background: var(--color-secondary);
	border-radius: 4px;
	padding: 3px 12px 3px 12px;
	cursor: pointer;
	font-size: 15px;
	user-select: none;
	min-width: 75px;
	display: inline-block;
}