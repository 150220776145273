.inputStyle {
	margin-left: 10px;
	background: var(--color-secondary);
	color: var(--color-text-primary);
	border: 1px solid rgba(43, 47, 54, 0.8);
	font-weight: 600;
	width: 100px;
	height: 32px;
	border-radius: 4px;
	padding: 3px 12px 3px 12px;
	outline: none;
}

form {
	display: inline-block;
	position: relative;
}

.title {
	min-width: 200px;
	text-transform: none;
}

.menuItem.noClose {
	text-transform: none;
}

.menuItem {
	padding-left: 30px;
	position: relative;
	font-size: 14px;
	font-weight: 400;
	letter-spacing: .7px;
	text-transform: none;
	padding-right: 24px !important;
}

.menuItem i {
	position: absolute;
	top: 9px;
	left: 10px;
}


/* settings filter */

.settingsFilter {
	margin-left: 30px;
	margin: 10px 0 0 30px;
	font-size: 14px;
	font-weight: 600;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.settingsPrice div {
	font-weight: 600;
	font-size: 14px;
}

input.inputStyle.price {
	font-size: 14px;
	margin: 8px 0 0 0;
}

.serviceLogo {
	display: inline-block;
	margin-left: 5px;
	margin-right: 5px;
}

.serviceLogo img {
	width: 14px;
	height: auto;
	position: relative;
	bottom: 1px;
}

.title .serviceLogo {
	margin-right: 4px;
}

.title .serviceLogo img {
	bottom: 2px;
}

.inputLabel {
	margin-right: 15px;
}

.inputLabel:last-child {
	margin-right: 0px;
}


/* no-access */

.menuItem.noAccess:hover {
	color: var(--color-text-primary) !important;
	background-color: var(--color-secondary) !important;
}

.menuItem.noAccess {
	cursor: default;
}

.noAccessPre {
	color: var(--color-main);
	font-weight: 600;
	font-size: 10px;
	float: right;
	margin-left: 3px;
}

.serviceTime {
	position: absolute;
	top: -30px;
	left: 10px;
	font-weight: 600;
	font-size: 14px;
}