.subInfo.flex.subShow {
	opacity: 1;
	pointer-events: all;
}

.subInfo[data-sub='1'] {
	--sub-color: var(--color-sub-base)
}

.subInfo[data-sub='2'] {
	--sub-color: var(--color-sub-pro)
}

.subInfo.flex {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: center;
}

.subInfo {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: 200;
	background: rgba(0, 0, 0, .8);
	user-select: none;
	opacity: 0;
	pointer-events: none;
	transition: .3s opacity ease;
}

.subHeader {
	background-color: var(--color-background);
	padding: 20px 30px;
	width: 800px;
	min-height: 500px;
	position: relative;
	border-radius: 25px;
	padding-bottom: 75px;
}

.subHeader .subTitle {
	font-size: 22px;
	font-weight: 600;
}

.subHeader .subTitle span {
	font-weight: 300;
	margin-left: 5px;
}

.subHeader .subTitle .imgKey {
	width: 32px;
	height: 32px;
}

.flexCenter {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-content: center;
	justify-content: flex-start;
	align-items: baseline;
}

.flexCol {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	align-content: center;
	justify-content: flex-start;
	align-items: stretch;
}

.subDescription {
	font-size: 14px;
}

.descriptionItem {
	padding: 0 10px;
	padding-bottom: 20px;
	user-select: text;
	cursor: default;
}

.descriptionItem .title {
	color: var(--sub-color);
	padding-bottom: 10px;
}

.descriptionItem .content {
	padding-left: 10px;
}

.descriptionItem .services {
	display: flex;
	column-gap: 18px;
	row-gap: 12px;
	flex-wrap: wrap;
}

.descriptionItem .services > * {
	display: inline-flex;
	gap: 5px;
	align-items: center;
}

.descriptionItem .services > *[data-disabled='true'] {
	color: darkred;
}

.descriptionItem .services img {
	height: 20px;
	width: 20px;
	object-fit: scale-down;
}

.btnPay {
	position: absolute;
	bottom: 30px;
	right: 40px;
	background: var(--color-secondary);
	border-radius: 4px;
	padding: 3px 12px 3px 12px;
	text-transform: capitalize;
	cursor: pointer;
	user-select: none;
	font-weight: 600;
	min-width: 125px;
	text-align: center;
}

.btnPay:hover {
	background: var(--sub-color)!important;
}

.subClose {
	position: absolute;
	top: 17px;
	right: 18px;
	font-size: 22px;
	font-weight: 600;
	padding: 0px 8px 4px 8px;
	border-radius: 50%;
	cursor: pointer;
}

.subClose:hover {
	color: var(--sub-color)!important;
}
