.subWarningTitle {
	color: darkred;
	text-align: center;
	font-size: 18px;
}

.settings .settingsPanel:not(:first-child) {
	margin-top: 50px;
}

.settings .settingsPanel {
	max-width: 80%;
	margin: auto;
}

.socAuth {
	margin: 15px 0;
	display: flex;
	justify-content: center;
	width: 100% !important;
}

.socInfo {
	padding-left: 5px;
	display: grid;
	grid-template-columns: 25% 70% 5%;
	align-items: center;
	font-weight: 600;
	width: 100%;
	word-break: break-word;
}

.socDisc>span {
	cursor: pointer;
}

.socInfo img {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	object-fit: cover;
}

.socImg {
	position: relative;
}

.socInfo .socImg::after {
	position: absolute;
	left: 18px;
	bottom: -20px;
	font-size: 11px;
}

.socInfo .socImg[data-status="yes"]::after {
	content: "✔️";
	color: var(--success);
}

.socInfo .socImg[data-status="not"]::after {
	content: "❌";
	color: darkred;
}

.settings .social .socItem {
	width: 350px;
	margin: 10px;
}

.settings .social .socItem input {
	display: block;
	text-align: left;
	width: 100%;
}

.tradeToken .tokenControls {
	display: grid;
	grid-template-columns: 1fr 0fr 0fr;
	grid-column-gap: 10px;
	grid-row-gap: 1em;
	padding: 0 0 5px 5px;
	font-size: 14px;
}

.settings .settingsTitle {
	text-align: center;
	color: var(--color-main);
	font-size: 24px;
	margin: 10px 0;
}

.settingsContainer {
	margin-top: 30px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
}

.settingsItem {
	display: inline-block;
	font-weight: 600;
	padding: 10px 20px;
}

.siTitle {
	text-align: center;
	font-size: 20px;
	margin-bottom: 10px;
}

.siTitle span {
	position: relative;
}

.siTitle img {
	height: 16px;
	position: absolute;
	top: 6px;
	left: -23px;
}

.settingsItem input {
	display: block;
}

.appList {
	display: inline-flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-content: center;
	overflow: hidden;
}

.appList img {
	width: 32px;
	height: auto;
	border-radius: 4px;
}

.appItem {
	padding: 0 3px;
	cursor: pointer;
	filter: grayscale(1);
}

.appItem.active {
	filter: grayscale(0);
}

.appItem:hover {
	filter: grayscale(0);
}
