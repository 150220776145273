.filterContent {
	user-select: none;
	min-width: 1200px;
	padding: 20px 0px;
}

.title {
	min-width: 200px;
	text-transform: none;
}

.menuItem.noClose {
	text-transform: none;
}

.menuItem {
	padding-left: 30px;
	position: relative;
	font-size: 14px;
	font-weight: 400;
	letter-spacing: .7px;
	text-transform: none;
	padding-right: 24px !important;
}

.menuItem i {
	position: absolute;
	top: 9px;
	left: 10px;
}

.settingsFilter {
	margin-left: 30px;
	margin: 10px 0 0 30px;
	font-size: 14px;
	font-weight: 600;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.settingsPrice div {
	font-weight: 600;
	font-size: 14px;
}

input.inputStyle.price {
	font-size: 14px;
	margin: 8px 0 0 0;
}

.serviceLogo {
	display: inline-block;
	margin-left: 5px;
	margin-right: 5px;
}

.serviceLogo img {
	width: 14px;
	height: auto;
	position: relative;
	bottom: 1px;
}

.title .serviceLogo {
	margin-right: 4px;
}

.title .serviceLogo img {
	bottom: 2px;
}

.menuItem.noAccess:hover {
	color: var(--color-text-primary) !important;
	background-color: var(--color-secondary) !important;
}

.menuItem.noAccess {
	cursor: default;
}

.noAccessPre {
	color: var(--color-main);
	font-weight: 600;
	font-size: 10px;
	float: right;
	margin-left: 3px;
}

.serviceTime {
	position: absolute;
	top: -30px;
	left: 10px;
	font-weight: 600;
	font-size: 14px;
}

.box {
	width: 100%;
	display: flex;
	justify-content: center;
}

.emptyItems {
	justify-content: center;
}

.inventoryLoading {
	width: 100%;
	display: flex;
	justify-content: center;
}

.inventoryEmpty {
	width: 100%;
	display: flex;
	justify-content: center;
}

.currencyItem {
	padding-left: 45px;
	position: relative;
	font-size: 14px;
	font-weight: 400;
	letter-spacing: .7px;
	text-transform: none;
}

.currentServices {
	text-decoration: underline;
}

.currencyItem>span.currencyIcon {
	position: absolute;
	left: 12px;
}

.inventory {
	background-color: #0000002b;
	border-radius: 15px;
	margin-bottom: 15px;
	width: 100%;
}

.pagination {
	background-color: var(--color-secondary);
    border-radius: 4px;
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: hidden;
    text-transform: uppercase;
}

.paginationItemPage {
	cursor: pointer;
    padding: 0 10px;
	width: 40px;
	height: 30px;
	font-size: 19px;
	text-align: center;
}

.paginationItemPage:hover {
	background-color: var(--color-menu-item-hover);
    box-shadow: 0 0 1px 0.25px #000000cc;
    color: var(--color-main);
}

.paginationCurrentPage {
	color: var(--color-main);
}

.listPage {
	display: flex;
    justify-content: center;
}

.updateInventoryBtn {
	align-items: center;
    background-color: var(--color-secondary);
    border: 1px solid rgba(43,47,54,.8);
    border-radius: 4px;
    color: var(--color-text-primary);
    display: inline-flex;
    font-weight: 600;
    height: 32px;
    min-width: 50px;
    padding: 3px 12px;
}

.updateInventoryBtn:hover {
	cursor: pointer;
    color: var(--color-secondary) !important;
    background-color: var(--color-main) !important;
}


.servicePriceItem {
	font-weight: 600;
    margin-bottom: 5px;
    width: 300px;
}

.serviceSelected {
	color: var(--color-main) !important;
}

.maxServicePrice {
	color: rgb(46, 163, 46);
}

.minServicePrice {
	color: rgb(163, 46, 46);
}

.servicePriceItem:last-child {
    margin-bottom: 0px;
}

/* .servicePriceItem:hover {
    color: var(--color-main) !important;
} */

.inventoryContent {
	display: flex;
}

.totalPricesHead {
	margin-bottom: 10px;
}

.countItems {
	position: absolute;
	margin-top: -32px;
}